import React, { useEffect, useRef, useState } from "react";
import {
    convertToBase64
} from "../../../_helper/convertToBase64";
import "../../../components/preview-feature/PreviewPage.css";
import API_FIREBASE from "../../../config/apifirebase";
import { userService } from "../../../services/user.service";
import constants from "../../../utils/constants";
import {
    mergeImages,
    PreviewImagePrompt,
    StoreBackgroundImagePrompt,
    ThemeImagePrompt,
    upperCase
} from "../../../utils/function";
import { base64RequiredImage, getStyleMaps } from "../../../utils/functionStyleMaps";
import LoadingScreen from "./Loading";
import PreviewAdsListing from "./PreviewAdsListing";

const ThemeImageAds = ({ setSellModal, setResetPreviousPage }) => {
    const [loading, setLoader] = useState(false);
    const [storeRequestLoader, setStoreRequestLoader] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileGenerateMore, setSelectedFileGenerateMore] =
        useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [url, setURL] = useState("");
    const [showModal, setShowModel] = useState(false);
    const [imageTags, setImageTags] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [filteredImages, setFilteredImages] = useState([]);
    const [copyImages, setCopyImages] = useState([]);
    const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);
    const [isGenerateMoreClicked, setIsGenerateMoreClicked] = useState(false);
    const [listingContent, setListingContent] = useState();
    const [highlight, setHighlight] = useState(false);
    const [showListingPage, setShowListingPage] = useState(false);

    const [urlErrorMessage, setURLErrorMessage] = useState(false);
    const [active, setActive] = useState(null);
    const [popupImage, setPopupImage] = useState(null);
    const [imageTitle, setImageTitle] = useState(null);
    const [copyItemsLength, setCopyItemsLength] = useState(0);
    const batchSize = 4;
    const [currentBatch, setCurrentBatch] = useState(batchSize);
    const [backgroundIdeas, setBackgroundIdeas] = useState("");
    const [imagesBase64Array, setImagesBase64Array] = useState([]);
    const [headlineArray, setHeadlineArray] = useState([]);
    const [descriptionArray, setDescriptionArray] = useState([]);
    const [ctaArray, setCtaArray] = useState([]);
    const [bulletpointsArray, setBulletpointsArray] = useState([]);
    const [reviewMessageArray, setReviewMessageArray] = useState([]);
    const [questionArray, setQuestionArray] = useState([]);
    const [reviewNameArray, setReviewNameArray] = useState([]);
    const [darkColorArray, setDarkColorArray] = useState([]);
    const [lightColorArray, setLightColorArray] = useState([]);

    const [isVariationCompleted, setIsVariationCompleted] = useState(false);
    // const [productPrompt, setProductPrompt] = useState("");
    const [productPromptErrorMessage, setProductPromptErrorMessage] =
        useState("");
    const [resBase64, setResBase64] = useState("");
    // Create a ref for the modal element
    const modalRef = useRef();
    let copyItems = [];

    useEffect(() => {
        userService.redirectToLogin();
    }, []);

    const updateVariations = (userRequestedIndex, variationNumber) => {

    };

    const handleErrors = async (error) => {
        await addErrorLogtoDebugWeb(error)
        setErrorMessage("Something went wrong! Please try again");
        setResetPreviousPage(true); // kishore added
        setTimeout(() => {
            setErrorMessage("");
        }, 3000);
        setLoader(false);
    };

    const filterBulletPoints = (inputString) => {
        if (Array.isArray(inputString) && inputString.length == 1) {
            const delimiter = /[-,|,||,-||-]/; // This regular expression matches hyphen, comma, or pipe
            return inputString[0]
                .split(delimiter)
                .filter(
                    (item) =>
                        item.trim() !== null &&
                        item.trim() !== undefined &&
                        item.trim() !== ""
                );
        } else {
            // Use the split() method with a regular expression
            return inputString.filter(
                (item) =>
                    item.trim() !== null &&
                    item.trim() !== undefined &&
                    item.trim() !== ""
            );
        }
    };

    const handleUpload = async (
        selectedFileInput = false,
        userAction = false
    ) => {
        const userId = userService.getUserId();
        if (!userId) {
            window.location.href = "/home";
        }
        // Check if copyItems has a value (is not empty)
        userService.setKeyValueLocalstorage("userSelectedAdTypePage", "upload-ad");
        setModalOpen(false);
        if (selectedFileInput === "generateMore") {
            selectedFileInput = userService.getValueFromLocalstorage("selectedFile");
        }

        if (selectedFileInput || active) {
            setLoader(true);
            let base64 = "";
            setShowModel(false);
            setShowListingPage(false);

            try {
                base64 = await convertToBase64(selectedFileInput);
                setResBase64(base64);

                const pureBase64 = base64.substring(base64.indexOf(",") + 1);

                if (userService.checkCredits()) {
                    const userInput = userService.getValueFromLocalstorage("uploadInput");
                    // FACEBOOK AND INSTAGRAM CODE START HERE
                    const objGenerateHeadlines = {
                        input: userInput,
                        userId,
                        type: "business",
                        prompt: PreviewImagePrompt(userInput),
                        maxIdeas: 1,
                    };

                    const objGenerateIdeas = {
                        userInput,
                        userId,
                        type: "business",
                        prompt: StoreBackgroundImagePrompt(userInput),
                        maxIdeas: 1,
                    };

                    let headlineArraysData = [];
                    let descriptionArray = [];

                    let ctaArrayData = [];
                    let bulletpointsArrayData = [];
                    let reviewMessageArraysData = [];
                    let questionArrayArray = [];
                    let reviewNameArrayData = [];

                    let lightColorArrayArray = [];
                    let darkColorArrayData = [];


                    try {
                        const [quickImageIdeaRes, IdeasRes] = await Promise.all([
                            API_FIREBASE.post("requestQuickImageIdeaNew", objGenerateHeadlines, {
                                headers: {
                                    authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY,
                                },
                            }),
                            API_FIREBASE.post("requestQuickImageIdeaNew", objGenerateIdeas, {
                                headers: {
                                    authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY,
                                },
                            })
                        ]);
                        const selectedTheme = userService.getValueFromLocalstorage("selectedTheme");
                        const selectedBackgroundName = userService.getValueFromLocalstorage("selectedBackground");

                        const objGenerateImage = {
                            userId,
                            formattedPrompt: ThemeImagePrompt(userInput, selectedTheme, selectedBackgroundName),
                            imageData: pureBase64,
                            backgroundIdea: IdeasRes.data,
                        };

                        // Call one more API using data from the first response
                        const backgroundReplacementRes = await API_FIREBASE.post("requestBackgroundReplacementWithoutCaptionsNew", objGenerateImage, {
                            headers: {
                                authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY,
                            },
                        });

                        // Handle quickImageIdea response
                        const quickImageIdeaData = quickImageIdeaRes?.data;
                        setListingContent(quickImageIdeaData);

                        // Handle backgroundReplacement response
                        const resCaption = backgroundReplacementRes.data;
                        const lightColor = resCaption.captions.lightColor ? resCaption.captions.lightColor : resCaption.captions.lightColor[0];
                        const darkColor = resCaption.captions.darkColor ? resCaption.captions.darkColor : resCaption.captions.darkColor[0];
                        const reviewName = resCaption.captions.reviewName;
                        const reviewMessage = resCaption.captions.reviewMessage;
                        const question = resCaption.captions.question;
                        userService.setKeyValueLocalstorage(
                            "lightColor",
                            resCaption.captions.lightColor
                        );
                        userService.setKeyValueLocalstorage(
                            "darkColor",
                            resCaption.captions.darkColor
                        );
                        userService.setKeyValueLocalstorage(
                            "question",
                            resCaption.captions.question
                        );
                        userService.setKeyValueLocalstorage(
                            "reviewMessage",
                            resCaption.captions.reviewMessage
                        );
                        userService.setKeyValueLocalstorage(
                            "reviewName",
                            resCaption.captions.reviewName
                        );

                        if (resCaption.imageURL) {
                            setImagesBase64Array(resCaption.imageBase64);
                            userService.setKeyValueLocalstorage(
                                "imageHeight",
                                resCaption.imgHeight
                            );
                            userService.setKeyValueLocalstorage(
                                "imageWidth",
                                resCaption.imgWidth
                            );
                            let headline = "";
                            let description = "";
                            let cta = "";
                            let processImagePromises = [];

                            for (let i = 0; i < constants.APPLICATION_INFO.UPLOAD_GENERATE_MAX_IMAGE; i++) {
                                const styleMaps = await getStyleMaps(i, lightColor, darkColor);
                                const bulletpoints = filterBulletPoints(
                                    resCaption.captions.bulletpoints
                                );
                                userService.setKeyValueLocalstorage("bulletpoints", bulletpoints);

                                if (userAction === "edit") {
                                    headline =
                                        userService.getValueFromLocalstorage("creativeAIheadline");
                                    description = userService.getValueFromLocalstorage(
                                        "creativeAIdescription"
                                    );
                                    cta = userService.getValueFromLocalstorage("creativeAIcta");
                                    userService.setKeyValueLocalstorage("ctaPreview", cta);
                                } else {
                                    userService.setKeyValueLocalstorage(
                                        "creativeAIheadline",
                                        upperCase(resCaption.captions.title)
                                    );
                                    userService.setKeyValueLocalstorage(
                                        "creativeAIdescription",
                                        resCaption.captions.description
                                    );
                                    userService.setKeyValueLocalstorage(
                                        "creativeAIcta",
                                        resCaption.captions.cta
                                    );
                                    userService.setKeyValueLocalstorage(
                                        "ctaPreview",
                                        resCaption.captions.cta
                                    );
                                    headline = resCaption.captions.title;
                                    description = resCaption.captions.description;
                                    cta = resCaption.captions.cta;
                                }

                                headlineArraysData.push(headline);
                                descriptionArray.push(description);
                                ctaArrayData.push(cta);
                                bulletpointsArrayData.push(bulletpoints);
                                reviewMessageArraysData.push(reviewMessage);
                                questionArrayArray.push(question);
                                reviewNameArrayData.push(reviewName);
                                lightColorArrayArray.push(lightColor);
                                darkColorArrayData.push(darkColor);

                                const objGenerateSecondImage = {
                                    title: upperCase(headline),
                                    description: description,
                                    cta: cta,
                                    height: resCaption.imgHeight ? resCaption.imgHeight : 450,
                                    width: resCaption.imgWidth ? resCaption.imgWidth : 450,
                                    bulletpoints: bulletpoints,
                                    style: styleMaps,
                                    reviewName: reviewName,
                                    reviewMessage: reviewMessage,
                                    question: question,
                                };
                                if (base64RequiredImage.includes(i)) {
                                    objGenerateSecondImage.base64Image = resCaption.imageBase64; // All product do not required to send base 64 images
                                }

                                const promise = API_FIREBASE.post("requestCaptionImageAdOverlayV1", objGenerateSecondImage, {
                                    headers: { authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY },
                                }).then(secondRes => mergeImages(resCaption.imageBase64, i, copyItems, secondRes.data, userAction)).catch(error => {
                                    // Handle the error
                                    console.log("merge image error", error)
                                });

                                promise.then((mergedImageDataUrl) => {
                                    copyItems[i] = mergedImageDataUrl;
                                }


                                ).catch(() => console.log(`Promise ${i} rejected`)).finally((res) => {
                                    console.log("res", res);
                                    console.log("Finally here")
                                });

                                processImagePromises.push(promise);

                            }

                            await Promise.all(processImagePromises);

                            if (userAction === "edit") {
                                setCopyImages(copyItems);
                            } else {
                                setCopyImages([...copyImages, ...copyItems]);
                            }
                            setLoader(false);
                            setShowListingPage(true);

                            setHeadlineArray(headlineArraysData);
                            setDescriptionArray(descriptionArray);
                            setCtaArray(ctaArrayData);
                            setBulletpointsArray(bulletpointsArrayData);
                            setReviewMessageArray(reviewMessageArraysData);
                            setQuestionArray(questionArrayArray);
                            setReviewNameArray(reviewNameArrayData);
                            setDarkColorArray(lightColorArrayArray);
                            setLightColorArray(darkColorArrayData);
                        } else {

                        }
                    } catch (error) {
                        await addErrorLogtoDebugWeb(error)
                        handleErrors(error);
                    }
                } else {
                    setLoader(false);
                    setErrorMessage("You do not have enough credits for this action.");
                }
            } catch (e) {
                if (e.response) {
                    console.error(
                        "Error hope readable message ",
                        e.response.data.message
                    );
                    setErrorMessage(e.response.data.message);
                } else {
                    console.error("Error simple message ", e.message);
                }
                await addErrorLogtoDebugWeb(e)
                setErrorMessage(
                    "There is some error while processing this image! Please try again!"
                );
                // setPaymentModalOpen(true);
                setLoader(false);
                setSelectedFile(null);
                setShowModel(true);
                setTimeout(function () {
                    setErrorMessage("");
                }, 3000);
            } finally {
                setSelectedFile(null);
                setIsGenerateMoreClicked(false);
            }
        } else {
            setLoader(false);
        }
    };

    const addErrorLogtoDebugWeb = async (e) => {
        console.log(e);
        console.log("There is error, we will open popup for error message.")
    };


    useEffect(() => {
        if (isGenerateMoreClicked) {
            setLoader(true);
            setShowListingPage(false);
            setStoreRequestLoader(false);
        }
    }, [isGenerateMoreClicked]);

    useEffect(() => {
        if (userService.getValueFromLocalstorage("selectedTheme") && userService.getValueFromLocalstorage("selectedBackground")) {
            handleUpload(userService.getValueFromLocalstorage("selectedFile"))
        }
    }, [userService.getValueFromLocalstorage("selectedTheme")]);

    console.log("loading", loading);
    console.log("showListingPage", showListingPage);

    return (
        <React.Fragment>
            {loading && !showListingPage && (
                <LoadingScreen></LoadingScreen>
            )}
            {showListingPage && (
                <PreviewAdsListing
                    handleUpload={handleUpload}
                    setShowListingPage={setShowListingPage}
                    showListingPage={showListingPage}
                    copyImages={copyImages}
                    setResetPreviousPage={setResetPreviousPage}
                    selectedFile={selectedFileGenerateMore}
                    setIsGenerateMoreClicked={setIsGenerateMoreClicked}
                    listingContent={listingContent}
                    pageName="store"
                    updateVariations={updateVariations}
                    isVariationCompleted={isVariationCompleted}
                    setIsVariationCompleted={setIsVariationCompleted}
                ></PreviewAdsListing>
            )}


        </React.Fragment>
    );
};
export default ThemeImageAds;
