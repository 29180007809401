import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PreviewAdsListing from "../landingpage/adFlow/PreviewAdsListing";
import LoadingScreen from "../landingpage/adFlow/Loading";
import Category from "../landingpage/conceptAd/Category";
import API_FIREBASE from "../../config/apifirebase";
import { userService } from "../../services/user.service";
import constants from "../../utils/constants";

import {
  ImageGeneratePrompt,
  NewHomeScreenPrompt,
  PreviewImagePrompt,
  getRandomNumber,
  getWebsiteTitle,
  loadImage,
  mergeImages,
  removeUndefined,
  upperCase,
} from "../../utils/function";
import {
  base64RequiredImage,
  getStyleMaps,
} from "../../utils/functionStyleMaps";
import Modal from "./Modal";
import ButtonGradient from "../common/Button/ButtonGradient";
import useWindowSize from "../../_helper/ScreenUtil";
import svg64 from "svg64"; // or use window.svg64.default
import Store from "../landingpage/adFlow/Store";
import RetryErrorModal from "../common/popup/RetryErrorModal";
import "./PreviewPage.css";

const PreviewPage = () => {
  const [loading, setLoader] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [userProductName, setUserProductName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showListingPage, setShowListingPage] = useState(false);
  const [resetPreviousPage, setResetPreviousPage] = useState(false);
  const [copyImages, setCopyImages] = useState([]);
  const [isVariationCompleted, setIsVariationCompleted] = useState(false);
  const [quickAdResponse, setQuickAdResponse] = useState("");
  const [buttonStatus, setButtonStatus] = useState(true);
  const [formData1, setFormData1] = useState([]);
  const [imagesBase64Array, setImagesBase64Array] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [paymentwarning, setPaymentwarning] = useState();
  const [sellModal, setSellModal] = useState(true);
  const [listingContent, setListingContent] = useState();
  const [showStore, setStore] = useState(true); // kishore added
  const [isRetryModalOpen, setIsRetryModalOpen] = useState(false); // kishore added
  const [styleMapVariationNumber, setStyleMapVariationNumber] = useState(0);
  const [headlineArray, setHeadlineArray] = useState([]);
  const [descriptionArray, setDescriptionArray] = useState([]);
  const [ctaArray, setCtaArray] = useState([]);
  const [bulletpointsArray, setBulletpointsArray] = useState([]);
  const [reviewMessageArray, setReviewMessageArray] = useState([]);
  const [questionArray, setQuestionArray] = useState([]);
  const [reviewNameArray, setReviewNameArray] = useState([]);
  const [darkColorArray, setDarkColorArray] = useState([]);
  const [lightColorArray, setLightColorArray] = useState([]);
  // Accessing the history instance created by React
  const navigate = useNavigate();
  useEffect(() => {
    setPaymentwarning(userService.downloadLeftImage());
  }, [userService.getValueFromLocalstorage("userSettings")]);

  useEffect(() => {
    userService.redirectToLogin();
  }, []);

  useEffect(() => {
    if (!userService.isPurchaser()) {
      window.open("/pricing", "_self");
    }

    if (userService.getValueFromLocalstorage("ideas")) {
      setSellModal(false);
      setShowListingPage(false);
      setShowPopup(false);
      setStore(false); // kishore added
      handleSubmitGenerateQuickAds();
    }
  }, []);

  useEffect(() => {
    if (userService.getValueFromLocalstorage("product-options") === "true") {
      updateButtonStatus(false);
      setStore(false);
      userService.removeKeyLocalstorage("product-options");
    }
  }, [userService.getValueFromLocalstorage("product-options")]);

  let copyItems = [];
  // Accessing the history instance created by React
  document.title = getWebsiteTitle() + "Home ";
  useEffect(() => {
    userService.setUserBackPage("new-home-screen");
  }, []);
  const { width,height } = useWindowSize();
  const isMobile = width <= 768;

  useEffect(() => {
    if (resetPreviousPage) {
      setShowListingPage(false);
      copyItems.length = 0;
      copyItems = [];
      setButtonStatus(true);
      setSellModal(true); // kishore added
      setStore(true); // kishore added
      // setResetPreviousPage(false);
    }
  }, [resetPreviousPage]);

  useEffect(() => {
    setTimeout(function () {
      setErrorMessage("");
    }, 4000);
  }, [errorMessage]);

  const updateButtonStatus = (newStatus) => {
    setButtonStatus(newStatus);
  };

  const handleErrors = (error) => {
    setErrorMessage("Something went wrong! Please try again");
    setResetPreviousPage(true); // kishore added
    setTimeout(() => {
      setErrorMessage("");
    }, 3000);
    setLoader(false);
  };

  const GenerateQuestion = async (conceptAdProductItems) => {
    userService.setKeyValueLocalstorage("uploadInputLoading", userProductName);
    userService.setKeyValueLocalstorage("userSelectedAdTypePage", "product-ad");
    const searchQuery = userProductName
      ? userProductName
      : conceptAdProductItems;
    if (searchQuery) {
      userService.setKeyValueLocalstorage("searchQuery", searchQuery);
    }
    setSellModal(false);
    setLoader(true);
    setShowListingPage(false);
    setShowPopup(false);
    setStore(false); // kishore added
    const maxIdeas = userService.getValueFromLocalstorage("maxIdeas");
    const type = userService.getValueFromLocalstorage("typeValue");
    const userId = userService.getUserId();
    const objGenerateImage = {
      input: searchQuery,
      userId,
      type: type ? type : "business",
      prompt: NewHomeScreenPrompt(searchQuery),
      maxIdeas: maxIdeas ? maxIdeas : 1,
    };
    try {
      const res = await API_FIREBASE.post(
        "requestQuickImageIdeaNew",
        objGenerateImage,
        {
          headers: {
            authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY,
          },
        }
      );
      if (res.status === 200) {
        setQuickAdResponse(res);
        userService.setKeyValueLocalstorage("quickAdResponse", res);
        try {
          const userDataString = res?.data;
          let questions = userDataString.match(/Question \d+: ([^\n]+)/g);
          let exampleAnswers = userDataString.match(
            /Example Answer: i\.e\. ([^\n]+)/g
          );
          if (!questions && !exampleAnswers) {
            questions = userDataString.match(
              /(\d+\)\s.*[?])\s*i\.e\.\s([^\n]+)/g
            );
            exampleAnswers = userDataString.match(/i\.e\.\s([^\n]+)/g);
          } else if (!questions && !exampleAnswers) {
            questions = userDataString.match(/\d+\. (.+)\?\n/i)[1];
            exampleAnswers = userDataString.match(/i\.e\. (.+)/i)[1];
          } else if (!questions && !exampleAnswers) {
            // Regular expressions to extract questions and example answers
            const questionPattern = /\d+\. (.+)\?\n/i;
            const answerPattern = /i\.e\. (.+)/i;
            // Extracting questions and example answers
            questions = userDataString
              .match(questionPattern)
              .map((match) => match.replace(/\n/g, ""))[1];
            exampleAnswers = userDataString
              .match(answerPattern)
              .map((match) => match.replace(/\n/g, ""))[1];
          }

          if (questions && exampleAnswers) {
            const userData = questions.map((question, index) => ({
              question: question.replace(/Question \d+: (.+)/, "$1").trim(),
              exampleAnswer: exampleAnswers[index]
                .replace(/Example Answer: i\.e\. (.+)/, "$1")
                .trim(),
            }));
            const initialFormData1 = userData.map((item, index) => ({
              constant: `${item.question}`,
              placeholder: `i.e. ${item.exampleAnswer}`,
              errorMessage: "",
              value: "",
              handleChange: (e) => handleFieldChange(e, index),
            }));
            setFormData1(initialFormData1);
            setShowPopup(true);
          } else {
            setErrorMessage("Failed to load please try again");
            setIsRetryModalOpen(true);
            setResetPreviousPage(true); // kishore added
            setSellModal(true);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    } catch (error) {
      handleErrors(error);
      setStore(true); // kishore added
    } finally {
      setLoader(false);
    }
  };

  const handleFieldChange = (e, index) => {
    setFormData1((prevFormData1) => {
      const updatedFormData = [...prevFormData1];
      const newValue = e.target.value;
      if (updatedFormData[index].value !== newValue) {
        updatedFormData[index].value = newValue;
        // Call handleChange function with the updated data
        updatedFormData[index].handleChange(e);
      }
      return updatedFormData;
    });
  };

  const handleAdType = () => {
    const searchQuery = userService.getValueFromLocalstorage("searchQuery");

    let concatenatedValues = formData1.reduce((acc, item) => {
      if (item.value.trim() !== "") {
        // Check if value is not empty or whitespace
        if (acc === "") {
          return item.value;
        } else {
          return acc + ", " + item.value;
        }
      } else {
        return acc;
      }
    }, "");
    concatenatedValues = concatenatedValues ? concatenatedValues : searchQuery;
    let combinedValues = concatenatedValues;
    // Check if concatenatedValues is not empty before adding a comma
    if (userProductName !== "" && combinedValues == "") {
      combinedValues += userProductName;
    }
    userService.setKeyValueLocalstorage("uploadInputLoading", userProductName);
    if (combinedValues && combinedValues.length > 2) {
      userService.setKeyValueLocalstorage("searchQuery", combinedValues); // after format the
      navigate("/product-ad-type");
    }
  };

  async function callAPI(objGenerateImage) {
    try {
      const res = await API_FIREBASE.post(
        "requestQuickAdImagesV2",
        objGenerateImage,
        {
          headers: {
            authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY,
          },
        }
      );
      return res.data; // Assuming the response data is what you want to merge
    } catch (error) {
      console.error("API call failed:", error);
      // throw error; // Re-throwing the error for handling outside
    }
  }

  const handleSubmitGenerateQuickAds = async (e, userAction = false) => {
    const searchQuery = userService.getValueFromLocalstorage("searchQuery");

    setLoader(true);
    setShowListingPage(false);
    setShowPopup(false);
    if (userAction === "edit") {
      copyItems = [];
      setCopyImages([]);
      getQuickAdsEditFeature("", "edit");
      return;
    }
    const maxIdeas = userService.getValueFromLocalstorage("maxIdeas");
    const type = userService.getValueFromLocalstorage("typeValue");
    const userId = userService.getUserId();
    let userInput =
      searchQuery + ", " + userService.getValueFromLocalstorage("ideas");

    const objGenerateImage = {
      input: userInput,
      userId,
      type: type ? type : "business",
      prompt: ImageGeneratePrompt(userInput),
      maxIdeas: maxIdeas ? maxIdeas : 1,
    };
    const objGenerateHeadlines = {
      input: userInput,
      userId,
      type: type ? type : "business",
      prompt: PreviewImagePrompt(userInput),
      maxIdeas: maxIdeas ? maxIdeas : 1,
    };

    /* try {
       const [resFacebookInstagramListing, res] = await Promise.all([
         API_FIREBASE.post("requestQuickImageSocialIdeas", objGenerateHeadlines, {
           headers: {
             authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY,
           },
         }),
         API_FIREBASE.post("requestQuickImageIdeaNew", objGenerateImage, {
           headers: {
             authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY,
           },
         }),
       ]);
     
       // Check if resFacebookInstagramListing has data
       const responseData = resFacebookInstagramListing?.data;
       if (responseData) {
         setListingContent(responseData);
         
       } else {
         // Handle the case where resFacebookInstagramListing does not contain data
         // This could be logging an error, showing a message to the user, etc.
         console.error("resFacebookInstagramListing did not return data");
       }
       userService.setKeyValueLocalstorage("quickAdResponse", res);
         await getQuickAds(res, userAction);
     } catch (error) {
       handleErrors(error);
     } finally {
       setLoader(false);
     }*/

    try {
      const resFacebookInstagramListing = await API_FIREBASE.post(
        "requestQuickImageIdeaNew",
        objGenerateHeadlines,
        {
          headers: {
            authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY,
          },
        }
      );
      const responseData = resFacebookInstagramListing?.data;
      setListingContent(responseData);
    } catch (error) {
      handleErrors(error);
    }
    try {
      const res = await API_FIREBASE.post(
        "requestQuickImageIdeaNew",
        objGenerateImage,
        {
          headers: {
            authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY,
          },
        }
      );

      userService.setKeyValueLocalstorage("quickAdResponse", res);
      await getQuickAds(res, userAction);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoader(false);
    }
  };

  const getQuickAds = async (responsePrompt, userAction = false) => {
    const userId = userService.getUserId();
    if (userService.getValueFromLocalstorage("quickAdResponse")) {
      const dataToFilterResponse =
        userService.getValueFromLocalstorage("quickAdResponse").data;
      // Extracting the bullet points using regular expressions
      // Split the response into lines
      const linesIdeas = dataToFilterResponse.split("\n");
      // Initialize variables to store data
      let idea = "";
      // Iterate through the lines and assign values to variables
      linesIdeas.forEach((line) => {
        if (line.startsWith("Image Ad Idea")) {
          idea = line.replace("Image Ad Idea: ", "").trim();
        }
      });

      idea +=
        ". (with the main focus on the " +
        userService.getValueFromLocalstorage("searchQuery") +
        ")";

      const objGenerateImage = {
        maxImages: constants.APPLICATION_INFO.MAX_IMAGE,
        userId,
        size: constants.APPLICATION_INFO.SIZE,
        prompt: idea,
        aiEngine: "StabilityAi",
      };

      // Call the API twice in parallel
      try {
        const [response1, response2] = await Promise.all([
          callAPI(objGenerateImage),
          callAPI(objGenerateImage),
        ]);
        // Merge the "imagesBase64" arrays into a single array
        const res = [...response1.imagesBase64, ...response2.imagesBase64];
        // Split the input data by lines
        if (userService.getValueFromLocalstorage("quickAdResponse")) {
          const dataToFilter =
            userService.getValueFromLocalstorage("quickAdResponse").data;
          // Extracting the bullet points using regular expressions
          // Split the response into lines
          const lines = dataToFilter.split("\n");

          // Initialize variables to store data
          let idea = "";
          let headline = "";
          let description = "";
          let cta = "";
          let reviewMessage = "";
          let reviewName = "";
          let question = "";
          let lightColor = "";
          let darkColor = "";
          let bulletPoints = [];
          const bulletPointsRegex = /^• (.+)$/;
          // Iterate through the lines and assign values to variables
          lines.forEach((line) => {
            if (line.startsWith("Image Ad Idea")) {
              idea = line.replace("Image Ad Idea: ", "").trim();
            } else if (line.startsWith("Headline")) {
              headline = upperCase(line.replace("Headline: ", "").trim());
            } else if (line.startsWith("Description")) {
              description = line.replace("Description: ", "").trim();
            } else if (line.startsWith("CTA")) {
              cta = line.replace("CTA: ", "").trim();
              userService.setKeyValueLocalstorage("ctaPreview", cta);
            } else if (line.startsWith("Review Message")) {
              reviewMessage = line.replace("Review Message: ", "").trim();
            } else if (line.startsWith("Review Name")) {
              reviewName = line.replace("Review Name: ", "").trim();
            } else if (line.startsWith("Question")) {
              question = line.replace("Question: ", "").trim();
            } else if (line.startsWith("Color 1 (Dark)")) {
              darkColor = line.replace("Color 1 (Dark): ", "").trim();
            } else if (line.startsWith("Color 2 (Light)")) {
              lightColor = line.replace("Color 2 (Light): ", "").trim();
            } else if (line.startsWith("Bullet Points")) {
              // Start collecting bullet points until the next title using regex
              let index = lines.indexOf(line) + 1;
              while (
                index < lines.length &&
                bulletPointsRegex.test(lines[index])
              ) {
                const match = lines[index].match(bulletPointsRegex);
                if (match) {
                  bulletPoints.push(match[1].trim());
                }
                index++;
              }
            }
          });


          if (Array.isArray(res)) {
            const totalImageCount = res.length;
            setImagesBase64Array(res);
            let headlineArraysData = [];
            let descriptionArray = [];

            let ctaArrayData = [];
            let bulletpointsArrayData = [];
            let reviewMessageArraysData = [];
            let questionArrayArray = [];
            let reviewNameArrayData = [];

            let lightColorArrayArray = [];
            let darkColorArrayData = [];
            let processImagePromises = [];
            for (let i = 0; i < totalImageCount; i++) {
              // for testing open below code
              const imagesBase64 = res[i];

              const styleMaps = await getStyleMaps(i, lightColor, darkColor);

              userService.setKeyValueLocalstorage("bulletpoints", bulletPoints);
              if (userAction === "edit") {
                headline =
                  userService.getValueFromLocalstorage("creativeAIheadline");
                description = userService.getValueFromLocalstorage(
                  "creativeAIdescription"
                );
                cta = userService.getValueFromLocalstorage("creativeAIcta");
              } else {
                userService.setKeyValueLocalstorage(
                  "creativeAIheadline",
                  headline
                );
                userService.setKeyValueLocalstorage(
                  "creativeAIdescription",
                  description
                );
                userService.setKeyValueLocalstorage("creativeAIcta", cta);
              }

              headlineArraysData.push(headline);
              descriptionArray.push(description);
              ctaArrayData.push(cta);
              bulletpointsArrayData.push(bulletPoints);
              reviewMessageArraysData.push(reviewMessage);
              questionArrayArray.push(question);
              reviewNameArrayData.push(reviewName);

              lightColorArrayArray.push(lightColor);
              darkColorArrayData.push(darkColor);

              let objGenerateSecondImage = {
                title: headline,
                description: description,
                cta: cta,
                height: 1024,
                width: 1024,
                bulletpoints: bulletPoints,
                style: styleMaps,
                question: question,
                reviewMessage: reviewMessage,
                reviewName: reviewName,
              };

              if (base64RequiredImage.includes(i)) {

                objGenerateSecondImage.base64Image = imagesBase64; // All product do not required to send base 64 images
              }

              /*try {
                const secondRes = await API_FIREBASE.post(
                  "requestCaptionImageAdOverlayV1",
                  objGenerateSecondImage,
                  {
                    headers: {
                      authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY,
                    },
                  }
                );

                // await mergeBase64ImagesimagesBase64, i, copyItems, secondRes.data);
                // Call mergeImages with a wait
                await mergeImages(
                  imagesBase64,
                  i,
                  copyItems,
                  secondRes.data,
                  totalImageCount
                );
              } catch (error) {

              } */
              // since i started from 0
              // i++;
              const promise = API_FIREBASE.post("requestCaptionImageAdOverlayV1", objGenerateSecondImage, {
                headers: {
                  authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY,
                },
              }).then(secondRes => {
                const data = mergeImages(imagesBase64, i, copyItems, secondRes.data, totalImageCount)


                return data
              }).catch(error => {
                // Handle the error
                console.log("merge image error", error)
              });
              promise.then((mergedImageDataUrl) => {
                copyItems[i] = mergedImageDataUrl
              }
              ).catch(() => console.log(`Promise ${i} rejected`));
              processImagePromises.push(promise);
              // Increment i
              // i++;
            }

            // Await all promises
            await Promise.all(processImagePromises);
            if (userAction === "edit") {
              setCopyImages(removeUndefined(copyItems));
            } else {
              setCopyImages(removeUndefined([...copyImages, ...copyItems]));
            }
            setLoader(false);
            setShowListingPage(true);

            setHeadlineArray(headlineArraysData);
            setDescriptionArray(descriptionArray);
            setCtaArray(ctaArrayData);
            setBulletpointsArray(bulletpointsArrayData);
            setReviewMessageArray(reviewMessageArraysData);
            setQuestionArray(questionArrayArray);
            setReviewNameArray(reviewNameArrayData);
            setDarkColorArray(lightColorArrayArray);
            setLightColorArray(darkColorArrayData);
          }
        }
      } catch (error) {
        handleErrors(error);
      } finally {
        setLoader(false);
      }
    }
  };

  const getQuickAdsEditFeature = async (responsePrompt, userAction = false) => {
    const userId = userService.getUserId();

    if (userService.getValueFromLocalstorage("quickAdResponse")) {
      const dataToFilterResponse =
        userService.getValueFromLocalstorage("quickAdResponse").data;
      // Extracting the bullet points using regular expressions
      // Split the response into lines
      const linesIdeas = dataToFilterResponse.split("\n");
      // Initialize variables to store data
      let idea = "";
      // Iterate through the lines and assign values to variables
      linesIdeas.forEach((line) => {
        if (line.startsWith("Image Ad Idea")) {
          idea = line.replace("Image Ad Idea: ", "").trim();
        }
      });

      idea +=
        ". (with the main focus on the " +
        userService.getValueFromLocalstorage("searchQuery") +
        ")";

      const objGenerateImage = {
        maxImages: constants.APPLICATION_INFO.MAX_IMAGE,
        userId,
        size: constants.APPLICATION_INFO.SIZE,
        prompt: idea,
        aiEngine: "StabilityAi",
      };

      try {
        // Split the input data by lines
        if (userService.getValueFromLocalstorage("quickAdResponse")) {
          const dataToFilter =
            userService.getValueFromLocalstorage("quickAdResponse").data;

          let headlineArraysData = [];
          let descriptionArray = [];

          let ctaArrayData = [];
          let bulletpointsArrayData = [];
          let reviewMessageArraysData = [];
          let questionArrayArray = [];
          let reviewNameArrayData = [];

          let lightColorArrayArray = [];
          let darkColorArrayData = [];
          // Extracting the bullet points using regular expressions
          // Split the response into lines
          const lines = dataToFilter.split("\n");

          // Initialize variables to store data
          let idea = "";
          let headline = "";
          let description = "";
          let cta = "";
          let reviewMessage = "";
          let reviewName = "";
          let question = "";
          let lightColor = "";
          let darkColor = "";
          let bulletPoints = [];
          const bulletPointsRegex = /^• (.+)$/;
          // Iterate through the lines and assign values to variables
          lines.forEach((line) => {
            if (line.startsWith("Image Ad Idea")) {
              idea = line.replace("Image Ad Idea: ", "").trim();
            } else if (line.startsWith("Headline")) {
              headline = upperCase(line.replace("Headline: ", "").trim());
            } else if (line.startsWith("Description")) {
              description = line.replace("Description: ", "").trim();
            } else if (line.startsWith("CTA")) {
              cta = line.replace("CTA: ", "").trim();
              userService.setKeyValueLocalstorage("ctaPreview", cta);
            } else if (line.startsWith("Review Message")) {
              reviewMessage = line.replace("Review Message: ", "").trim();
            } else if (line.startsWith("Review Name")) {
              reviewName = line.replace("Review Name: ", "").trim();
            } else if (line.startsWith("Question")) {
              question = line.replace("Question: ", "").trim();
            } else if (line.startsWith("Color 1 (Dark)")) {
              darkColor = line.replace("Color 1 (Dark): ", "").trim();
            } else if (line.startsWith("Color 2 (Light)")) {
              lightColor = line.replace("Color 2 (Light): ", "").trim();
            } else if (line.startsWith("Bullet Points")) {
              // Start collecting bullet points until the next title using regex
              let index = lines.indexOf(line) + 1;
              while (
                index < lines.length &&
                bulletPointsRegex.test(lines[index])
              ) {
                const match = lines[index].match(bulletPointsRegex);
                if (match) {
                  bulletPoints.push(match[1].trim());
                }
                index++;
              }
            }
          });
          let processImagePromises = [];
          if (Array.isArray(imagesBase64Array)) {
            const totalImageCount = imagesBase64Array.length;
            for (let i = 0; i < totalImageCount; i++) {
              // for testing open below code
              const imagesBase64 = imagesBase64Array[i];

              const styleMaps = await getStyleMaps(i, lightColor, darkColor);

              userService.setKeyValueLocalstorage("bulletpoints", bulletPoints);

              headline =
                userService.getValueFromLocalstorage("creativeAIheadline");
              description = userService.getValueFromLocalstorage(
                "creativeAIdescription"
              );
              cta = userService.getValueFromLocalstorage("creativeAIcta");

              headlineArraysData.push(headline);
              descriptionArray.push(description);
              ctaArrayData.push(cta);
              bulletpointsArrayData.push(bulletPoints);
              reviewMessageArraysData.push(reviewMessage);
              questionArrayArray.push(question);
              reviewNameArrayData.push(reviewName);

              lightColorArrayArray.push(lightColor);
              darkColorArrayData.push(darkColor);

              const objGenerateSecondImage = {
                title: headline,
                description: description,
                cta: cta,
                height: 1024,
                width: 1024,
                bulletpoints: bulletPoints,
                style: styleMaps,
                question: question,
                reviewMessage: reviewMessage,
                reviewName: reviewName,
              };

              if (base64RequiredImage.includes(i)) {

                objGenerateSecondImage.base64Image = imagesBase64; // All product do not required to send base 64 images
              }

              /*try {
                const secondRes = await API_FIREBASE.post(
                  "requestCaptionImageAdOverlayV1",
                  objGenerateSecondImage,
                  {
                    headers: {
                      authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY,
                    },
                  }
                );

                // await mergeBase64ImagesimagesBase64, i, copyItems, secondRes.data);
                // Call mergeImages with a wait
                await mergeImages(
                  imagesBase64,
                  i,
                  copyItems,
                  secondRes.data,
                  totalImageCount,
                  userAction
                );
              } catch (error) {
                console.log("there is error");
              }*/
              const promise = API_FIREBASE.post("requestCaptionImageAdOverlayV1", objGenerateSecondImage, {
                headers: {
                  authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY,
                },
              }).then(secondRes => mergeImages(imagesBase64, i, copyItems, secondRes.data, totalImageCount)).catch(error => {
                // Handle the error
                console.log("merge image error", error)
              });
              promise.then((mergedImageDataUrl) => {
                copyItems[i] = mergedImageDataUrl;
              }
              ).catch(() => console.log(`Promise ${i} rejected`));
              processImagePromises.push(promise);
            }

            // Await all promises
            await Promise.all(processImagePromises);
            if (userAction === "edit") {
              setCopyImages(removeUndefined(copyItems));
            } else {
              setCopyImages(removeUndefined([...copyImages, ...copyItems]));
            }
            setLoader(false);
            setShowListingPage(true);

            setHeadlineArray(headlineArraysData);
            setDescriptionArray(descriptionArray);
            setCtaArray(ctaArrayData);
            setBulletpointsArray(bulletpointsArrayData);
            setReviewMessageArray(reviewMessageArraysData);
            setQuestionArray(questionArrayArray);
            setReviewNameArray(reviewNameArrayData);
            setDarkColorArray(lightColorArrayArray);
            setLightColorArray(darkColorArrayData);
          }
        }
      } catch (error) {
        handleErrors(error);
      } finally {
        setLoader(false);
      }
    }
  };

  const updateVariations = (userRequestedIndex, variationNumber) => {
    return new Promise(async (resolve, reject) => {
      let styleMapVariationCount = styleMapVariationNumber;
      if (styleMapVariationNumber) {
        setStyleMapVariationNumber(styleMapVariationNumber + 1);
        styleMapVariationCount++;
      }

      try {
        if (Array.isArray(imagesBase64Array)) {
          const totalImageCount = imagesBase64Array.length;
          let i = userRequestedIndex;
          const randomImageIndex = getRandomNumber(totalImageCount, 0);
          const imagesBase64 = imagesBase64Array[randomImageIndex];
          const styleMaps = await getStyleMaps(
            randomImageIndex,
            lightColorArray[i],
            darkColorArray[i]
          );
          const headline = headlineArray[i];
          const description = descriptionArray[i];
          const cta = ctaArray[i];
          const bulletpoints = bulletpointsArray[i];
          const reviewMessage = reviewMessageArray[i];
          const question = questionArray[i];
          const reviewName = reviewNameArray[i];

          const objGenerateSecondImage = {
            title: headline,
            description: description,
            cta,
            height: 1024,
            width: 1024,
            bulletpoints,
            style: styleMaps,
            question,
            reviewMessage,
            reviewName,
          };

          if (base64RequiredImage.includes(variationNumber)) {
            objGenerateSecondImage.base64Image = imagesBase64; // All product do not required to send base 64 images
          }

          const secondRes = await API_FIREBASE.post(
            "requestCaptionImageAdOverlayV1",
            objGenerateSecondImage,
            {
              headers: {
                authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY,
              },
            }
          );

          const mergedImageDataUrl = await mergeImages(
            imagesBase64,
            i,
            copyItems,
            secondRes.data,
            totalImageCount,
            "",
            true // it is variations
          );
          copyImages.splice(i, 1, mergedImageDataUrl);
          setCopyImages(removeUndefined(copyImages));
          setIsVariationCompleted(true);
          userService.setKeyValueLocalstorage("variationImage", i);
          resolve(true); // Resolve the promise with true upon success
        }
      } catch (error) {
        handleErrors(error);
        reject(false); // Reject the promise with false upon error
      } finally {
        setLoader(false);
      }
    });
  };





  const handleChangename = (e, index) => {
    const newFormData = [...formData];
    newFormData[index].value = e.target.value;
    setUserProductName(newFormData[index].value);
  };

  const handleRetryModelAction = () => {
    setIsRetryModalOpen(false);
  };

  const [formData, setFormData] = useState([
    {
      constant: "What do you sell?",
      errorMessage: errorMessage,
      value: userProductName,
      handleChange: handleChangename,
      placeholder: "Ex: brown leather jacket",
    },
  ]);

  return (
    <div className=" " > 
      {sellModal && (
        <>
          <div className="font-family-vietnam-pro ">
            {buttonStatus && (
              <>
                <Modal
                  formData={formData}
                  errorMessage={errorMessage}
                  ShowPopup={GenerateQuestion}
                  modalAddOnClass="padding-top-60"
                />

              </>
            )}
          </div>
        </>
      )}
      {sellModal && showStore &&
      <div className="">
      <div className=" or-with-horizontal-line-new  ">
        <div className="tecenter ">OR</div>
      </div>
      </div>
      }
      {showStore && (
        <div className="new-home-screen-store-section ">
          <Store
            setSellModal={setSellModal}
            setStore={setStore}
            setResetPreviousPage={setResetPreviousPage}
          />
        </div>
      )}
      {!errorMessage && (
        <RetryErrorModal
          GenerateQuestion={GenerateQuestion}
          isMobile={isMobile}
          isRetryModalOpen={isRetryModalOpen}
          handleRetryModelAction={handleRetryModelAction}
        ></RetryErrorModal>
      )}
      {showListingPage && (
        <PreviewAdsListing
          handleUpload={handleSubmitGenerateQuickAds}
          setShowListingPage={setShowListingPage}
          showListingPage={showListingPage}
          listingContent={listingContent}
          copyImages={copyImages}
          setResetPreviousPage={setResetPreviousPage}
          updateVariations={updateVariations}
          pageName="home"
          isVariationCompleted={isVariationCompleted}
          setIsVariationCompleted={setIsVariationCompleted}
        />
      )}

      {loading && !showListingPage && <LoadingScreen />}

      {sellModal && (
        <>
          <div className="font-family-vietnam-pro">
            {buttonStatus && (
              <>
                <div className="text-white text-center  display-mauto">
                  <h3>Or tap a niche: </h3>
                </div>
              </>
            )}
            <Category
              GenerateQuestion={GenerateQuestion}
              buttonStatus={buttonStatus}
              updateButtonStatus={updateButtonStatus}
              setStore={setStore}
            />
          </div>
        </>
      )}

      {showPopup && (
          <div style={{
            height:width<481 || width >768? height  :height-100
          }} className="font-family-vietnam-pro justifyCenter ">
        <Modal
              formData={formData1}
              handleAdType={handleAdType}
              handleChange={handleFieldChange}
            /> 
          </div>
      )}

      {paymentwarning == 0 && (
        <div
          className={`modal overflows-auto bg-dark-opacity-10 ${isModalOpen ? "show" : ""
            }`}
          style={{
            display: isModalOpen ? "block" : "none",
            marginLeft: isMobile ? "" : "12%",
          }}
        >
          <div
            className="modal-dialog modal-600 modal-350-mobile modal-dialog-centered font-family-vietnam-pro top-20-percent"
            role="document"
          >
            <div className="modal-content modal-gradient-new">
              <div className="modal-body text-center p-60">
                <div className="">
                  <div className="">
                    <h1 className="text-white text-size-30 m-0">
                      <span className="text-weight-600">
                        You have {paymentwarning} ads remaining renew ur plan
                      </span>
                    </h1>
                  </div>
                  <div className="text-center mt-20">
                    <ButtonGradient
                      title="Dismiss"
                      stylebyclass="gradient-head-text text-size-24 text-black"
                      btnstyle={{
                        background: "white",
                        height: "40px",
                        padding: "3px",
                      }}
                      btnstylebyclass="width-80-percent m-left-right-auto mt-10 mb-10"
                      onClick={() => {
                        setModalOpen(false);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default PreviewPage;
