import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Rolling_circle_loader from "../../../images/icons/Rolling_circle_loader.svg";
import { userService } from "../../../services/user.service";
import {
  getWebsiteTitle,
  removeSpecialCharacters,
} from "../../../utils/function";

const LoadingSecond = (props) => {
  document.title = getWebsiteTitle() + "Brainstorming...";
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  let randomText = [
    "Generating Ad Ideas... ",
    "Preparing Colors...",
    "Researching Text...",
    "Designing 16+ Ads... ",
    "Adding Text... ",
    "Finalizing Images...",
    "Finalizing Colors... ",
    "Enhancing Quality... ",
    "Polishing...",
    "Hang on…",
  ];

  useEffect(() => {
    let pageName = "";
    let intervalTime = 350;
    let random = 5;

    if (props.pagename === "adideas") {
      pageName = "adideas";
      intervalTime = 500;
      random = 25;
    } else {
      pageName = userService.getValueFromLocalstorage("userSelectedAdTypePage");
    }

    if (pageName === "quick-ad" || pageName === "concept-ad") {
      intervalTime = 1200;
      random = 3;
    }


    // ********************** Loader timing logic and percentage increment ********************************************
    let number = document.getElementById("number");
    let count = 0;
    let intervalIdRef = setInterval(() => {
      if (count == 99) {
        count -= 1;
        //clearInterval(intervalId);
         setLoading(true);
      } else {
        count += 1;
        number.innerHTML = count + "%";
      }
      setLoadingProgress(count);
    }, 500);
    // Clear the interval when the component is unmounted
  return () => {
    clearInterval(intervalIdRef);
  };
  }, []);

  let currentText = randomText[0];
  switch (true) {
    case loadingProgress > 0 && loadingProgress < 10:
      currentText = randomText[0];
      break;
    case loadingProgress >= 10 && loadingProgress < 20:
      currentText = randomText[1];
      break;
    case loadingProgress >= 20 && loadingProgress < 30:
      currentText = randomText[2];
      break;
    case loadingProgress >= 30 && loadingProgress < 40:
      currentText = randomText[3];
      break;
    case loadingProgress >= 40 && loadingProgress < 55:
      currentText = randomText[4];
      break;
    case loadingProgress >= 55 && loadingProgress < 65:
      currentText = randomText[5];
      break;
    case loadingProgress >= 65 && loadingProgress <= 75:
      currentText = randomText[6];
      break;
    case loadingProgress >= 75 && loadingProgress <= 80:
      currentText = randomText[7];
      break;
    case loadingProgress >= 80 && loadingProgress <= 88:
      currentText = randomText[8];
      break;
    case loadingProgress >= 95:
      currentText = randomText[9];
      break;

    default:
      // Handle the case when loadingProgress is outside of the specified ranges
      currentText = "Preparing Ad...";
      break;
  }

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row ">
          <div className="col ">
            <div className="top-contentDIv  loading-container-new-lading-page">
              <div className="top-ranckking  col-xs-12 justifyCenter ">
                {/* //////////////// */}
                <div >
                  <div className="loading-items-container2 b loader-two">
                    {/* <div className="circle-wrapper">
                      <div className="circle">
                        <div className="loading-text">{loadingProgress}%</div>
                      </div>
                    </div> */}

                    <div className="skill">

                      <div className="outers">
                        <div className="inners">
                          <div id="number"></div>
                        </div>
                      </div>

                      <div className="svg-outer-div  ">
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="300px" height="300px">
                          <defs>
                            <linearGradient id="GradientColor">
                              <stop offset="0%" stop-color="#C63CF9" />
                              <stop offset="68%" stop-color="#32D1E9" />
                              <stop offset="100%" stop-color="#62A0EE" />
                            </linearGradient>
                          </defs>
                          <circle cx="150" cy="150" r="140" stroke-linecap="round" />
                        </svg>
                      </div>
                    </div>
                    <div className="progress-bar-and-content-container ">
                      <div className=" text-center">
                        <span className={`progress-random-text text-center ${removeSpecialCharacters(currentText)}`}>{currentText}</span>
                      </div>
                      {loading && (
                        <div className="row ">
                          <div className=" text-center">
                            
                            <img
                              width={50}
                              className=""
                              src={Rolling_circle_loader}
                              alt="Generating Ads..."
                              title="Generating Ads..."
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* //////////////// */}
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default LoadingSecond;
