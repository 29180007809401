import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { auth, db } from "../../../utils/Firebase";
import { useNavigate } from "react-router-dom";
import {
    convertToBase64,
    imageUrlToBase64,
} from "../../../_helper/convertToBase64";
import API_FIREBASE from "../../../config/apifirebase";
import cross from "../../../images/icons/cross.png";
import { userService } from "../../../services/user.service";
import constants from "../../../utils/constants";
import {
    StoreImagePrompt,
    StoreBackgroundImagePrompt,
    PreviewImagePrompt,
    StoreAdFlowUserPrompt,
    correctImageUrl,
    getRandomNumber,
    getWebsiteTitle,
    updateNewSignupUserStatus,
    upperCase,
    loadImage,
    mergeImages,
} from "../../../utils/function";
import StyledButton1 from "../../common/Button/StyledButton1";
import PreviewAdsListing from "./PreviewAdsListing";
import LoadingScreen from "./Loading";
import LoadingStore from "./LoadingStore";
import CheckoutYearlyUltra from "../../common/StripePayment/CheckoutYearlyUltra";
import CheckoutMonthlyUltra from "../../common/StripePayment/CheckoutMonthlyUltra";
import {
    base64RequiredImage,
    getStyleMaps,
} from "../../../utils/functionStyleMaps";
import upload_1 from "../../../images/icons/upload_1.svg";
import "../../../components/preview-feature/PreviewPage.css";

const Store = ({ setSellModal, setResetPreviousPage, setStore }) => {
    document.title = getWebsiteTitle() + "Get Store Data";
    const [loading, setLoader] = useState(false);
    const [storeRequestLoader, setStoreRequestLoader] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileGenerateMore, setSelectedFileGenerateMore] =
        useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [url, setURL] = useState("");
    const [showModal, setShowModel] = useState(false);
    const [imageTags, setImageTags] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [filteredImages, setFilteredImages] = useState([]);
    const [copyImages, setCopyImages] = useState([]);
    const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);
    const [isGenerateMoreClicked, setIsGenerateMoreClicked] = useState(false);
    const [listingContent, setListingContent] = useState();
    const [highlight, setHighlight] = useState(false);
    const [showListingPage, setShowListingPage] = useState(false);

    const [urlErrorMessage, setURLErrorMessage] = useState(false);
    const [active, setActive] = useState(null);
    const [popupImage, setPopupImage] = useState(null);
    const [imageTitle, setImageTitle] = useState(null);
    const [copyItemsLength, setCopyItemsLength] = useState(0);
    const batchSize = 4;
    const [currentBatch, setCurrentBatch] = useState(batchSize);
    const [backgroundIdeas, setBackgroundIdeas] = useState("");
    const [imagesBase64Array, setImagesBase64Array] = useState([]);
    const [headlineArray, setHeadlineArray] = useState([]);
    const [descriptionArray, setDescriptionArray] = useState([]);
    const [ctaArray, setCtaArray] = useState([]);
    const [bulletpointsArray, setBulletpointsArray] = useState([]);
    const [reviewMessageArray, setReviewMessageArray] = useState([]);
    const [questionArray, setQuestionArray] = useState([]);
    const [reviewNameArray, setReviewNameArray] = useState([]);
    const [darkColorArray, setDarkColorArray] = useState([]);
    const [lightColorArray, setLightColorArray] = useState([]);
    const [chooseBgPopup, setChooseBgPopup] = useState(false);
    const navigate = useNavigate();

    const [isVariationCompleted, setIsVariationCompleted] = useState(false);
    // const [productPrompt, setProductPrompt] = useState("");
    const [productPromptErrorMessage, setProductPromptErrorMessage] =
        useState("");
    const [resBase64, setResBase64] = useState("");
    // Create a ref for the modal element
    const modalRef = useRef();
    let copyItems = [];

    useEffect(() => {
        userService.redirectToLogin();
    }, []);

    const handleShowMore = () => {
        setLoader(false);
        setCurrentBatch((prevBatch) => prevBatch + batchSize);
    };

    const [formData, setFormData] = useState({
        url: "",
        productPrompt: "",
    });

    useEffect(() => {
        setFilteredImages(userService.getValueFromLocalstorage("storeImages"));
    }, []);

    const handleChange = (event) => {
        let { name, value } = event.target;
        value = value.replace("https://", "");

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handlePromptChange = (event) => {
        const { name, value } = event.target;
        userService.setKeyValueLocalstorage("uploadInput", event.target.value);
        userService.setKeyValueLocalstorage(
            "uploadInputLoading",
            event.target.value
        );

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    let timeoutId;

    const handleFileChange = async (event) => {
        clearTimeout(timeoutId); // Clear previous timeout if exists

        const file = event.target.files[0];
        setSelectedFile(file);
        const acceptedTypes = [
            "image/jpeg",
            "image/jpg",
            "image/png",
            "image/webp",
        ];
        const maxSize = 1200 * 1024; // 400KB in bytes

        if (!file) {
            // No file selected
            setSelectedFile(null);
            setShowModel(true);
            setErrorMessage("Please select an image file!");
        } else if (!acceptedTypes.includes(file.type)) {
            // Invalid file type
            setSelectedFile(null);
            setShowModel(true);
            setErrorMessage("Only JPEG, JPG, PNG, and WEBP images are allowed!");
        } else if (file.size > maxSize) {
            // File size exceeds the limit
            setSelectedFile(null);
            setShowModel(true);
            setErrorMessage("Maximum file size allowed is 1200KB!");
        } else {
            // File meets all criteria
            setSelectedFile(file);
            userService.setKeyValueLocalstorage("selectedFile", file);
            setSelectedFileGenerateMore(file);
            setErrorMessage("");
            setModalOpen(true);
            // setLoader(true);
            // await handleUpload(file);
        }

        // Hide error message after 10 seconds
        timeoutId = setTimeout(() => {
            setErrorMessage("");
        }, 10000);
    };


    const filterBulletPoints = (inputString) => {
        if (Array.isArray(inputString) && inputString.length == 1) {
            const delimiter = /[-,|,||,-||-]/; // This regular expression matches hyphen, comma, or pipe
            return inputString[0]
                .split(delimiter)
                .filter(
                    (item) =>
                        item.trim() !== null &&
                        item.trim() !== undefined &&
                        item.trim() !== ""
                );
        } else {
            // Use the split() method with a regular expression
            return inputString.filter(
                (item) =>
                    item.trim() !== null &&
                    item.trim() !== undefined &&
                    item.trim() !== ""
            );
        }
    };

    const handleErrors = async (error) => {
        setErrorMessage("Something went wrong! Please try again");
        await addErrorLogtoDebugWeb(error)
        setResetPreviousPage(true); // kishore added
        setTimeout(() => {
            setErrorMessage("");
        }, 3000);
        setLoader(false);
    };

    const handleUpload = async (
        selectedFileInput = false,
        userAction = false
    ) => {
        const userId = userService.getUserId();

        if (!userId) {
            window.location.href = "/home";
            return;
        }

        if (selectedFileInput === "generateMore") {
            setIsGenerateMoreClicked(true);
        }
        userService.setKeyValueLocalstorage("userSelectedAdTypePage", "upload-ad");
        setModalOpen(false);

        const selectedFileToProcess = selectedFile || selectedFileGenerateMore;
        if (!selectedFileToProcess && !active) {
            setLoader(false);
            setSellModal(false);
            return;
        }

        setLoader(true);
        setShowModel(false);
        setSellModal(false);
        setChooseBgPopup(false);

        try {
            const base64 = await convertToBase64(selectedFileToProcess);
            setResBase64(base64);
            const pureBase64 = base64.substring(base64.indexOf(",") + 1);

            if (!userService.checkCredits()) {
                setLoader(false);
                setErrorMessage("You do not have enough credits for this action.");
                return;
            }

            const userInput = userService.getValueFromLocalstorage("uploadInput");

            const objGenerateHeadlines = {
                input: userInput,
                userId,
                type: "business",
                prompt: PreviewImagePrompt(userInput),
                maxIdeas: 1,
            };

            const objGenerateIdeas = {
                userInput,
                userId,
                type: "business",
                prompt: StoreBackgroundImagePrompt(userInput),
                maxIdeas: 1,
            };

            const [quickImageIdeaRes, IdeasRes] = await Promise.all([
                API_FIREBASE.post("requestQuickImageIdeaNew", objGenerateHeadlines, { headers: { authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY } }),
                API_FIREBASE.post("requestQuickImageIdeaNew", objGenerateIdeas, { headers: { authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY } }),
            ]);

            const objGenerateImage = {
                userId,
                formattedPrompt: StoreImagePrompt(userInput),
                imageData: pureBase64,
                backgroundIdea: IdeasRes.data,
            };

            const backgroundReplacementRes = await API_FIREBASE.post(
                "requestBackgroundReplacementWithoutCaptionsNew",
                objGenerateImage,
                {
                    headers: {
                        authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY,
                    },
                }
            );

            const quickImageIdeaData = quickImageIdeaRes?.data;
            setListingContent(quickImageIdeaData);

            const resCaption = backgroundReplacementRes.data;
            const lightColor = resCaption.captions.lightColor
                ? resCaption.captions.lightColor
                : resCaption.captions.lightColor[0];
            const darkColor = resCaption.captions.darkColor
                ? resCaption.captions.darkColor
                : resCaption.captions.darkColor[0];
            const reviewName = resCaption.captions.reviewName;
            const reviewMessage = resCaption.captions.reviewMessage;
            const question = resCaption.captions.question;
            userService.setKeyValueLocalstorage(
                "lightColor",
                resCaption.captions.lightColor
            );
            userService.setKeyValueLocalstorage(
                "darkColor",
                resCaption.captions.darkColor
            );
            userService.setKeyValueLocalstorage(
                "question",
                resCaption.captions.question
            );
            userService.setKeyValueLocalstorage(
                "reviewMessage",
                resCaption.captions.reviewMessage
            );
            userService.setKeyValueLocalstorage(
                "reviewName",
                resCaption.captions.reviewName
            );

            if (resCaption.imageURL) {
                setImagesBase64Array(resCaption.imageBase64);
                userService.setKeyValueLocalstorage(
                    "imageHeight",
                    resCaption.imgHeight
                );
                userService.setKeyValueLocalstorage(
                    "imageWidth",
                    resCaption.imgWidth
                );
                let headline = "";
                let description = "";
                let cta = "";
                const headlineArraysData = [];
                const descriptionArray = [];
                const ctaArrayData = [];
                const bulletpointsArrayData = [];
                const reviewMessageArraysData = [];
                const questionArrayArray = [];
                const reviewNameArrayData = [];
                const lightColorArrayArray = [];
                const darkColorArrayData = [];
                const processImagePromises = [];
                const copyItems = []; // Initialize copyItems here
                for (
                    let i = 0;
                    i < constants.APPLICATION_INFO.UPLOAD_GENERATE_MAX_IMAGE;
                    i++
                ) {
                    const styleMaps = await getStyleMaps(i, lightColor, darkColor);
                    const bulletpoints = filterBulletPoints(
                        resCaption.captions.bulletpoints
                    );
                    userService.setKeyValueLocalstorage(
                        "bulletpoints",
                        bulletpoints
                    );

                    if (userAction === "edit") {
                        headline =
                            userService.getValueFromLocalstorage("creativeAIheadline");
                        description = userService.getValueFromLocalstorage(
                            "creativeAIdescription"
                        );
                        cta = userService.getValueFromLocalstorage("creativeAIcta");
                        userService.setKeyValueLocalstorage("ctaPreview", cta);
                    } else {
                        userService.setKeyValueLocalstorage(
                            "creativeAIheadline",
                            upperCase(resCaption.captions.title)
                        );
                        userService.setKeyValueLocalstorage(
                            "creativeAIdescription",
                            resCaption.captions.description
                        );
                        userService.setKeyValueLocalstorage(
                            "creativeAIcta",
                            resCaption.captions.cta
                        );
                        userService.setKeyValueLocalstorage(
                            "ctaPreview",
                            resCaption.captions.cta
                        );
                        headline = resCaption.captions.title;
                        description = resCaption.captions.description;
                        cta = resCaption.captions.cta;
                    }

                    headlineArraysData.push(headline);
                    descriptionArray.push(description);
                    ctaArrayData.push(cta);
                    bulletpointsArrayData.push(bulletpoints);
                    reviewMessageArraysData.push(reviewMessage);
                    questionArrayArray.push(question);
                    reviewNameArrayData.push(reviewName);
                    lightColorArrayArray.push(lightColor);
                    darkColorArrayData.push(darkColor);

                    const objGenerateSecondImage = {
                        title: upperCase(headline),
                        description: description,
                        cta: cta,
                        height: resCaption.imgHeight ? resCaption.imgHeight : 450,
                        width: resCaption.imgWidth ? resCaption.imgWidth : 450,
                        bulletpoints: bulletpoints,
                        style: styleMaps,
                        reviewName: reviewName,
                        reviewMessage: reviewMessage,
                        question: question,
                    };
                    if (base64RequiredImage.includes(i)) {
                        objGenerateSecondImage.base64Image = resCaption.imageBase64; // All product do not required to send base 64 images
                    }


                    const promise = API_FIREBASE.post("requestCaptionImageAdOverlayV1", objGenerateSecondImage, {
                        headers: { authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY },
                    }).then(secondRes => mergeImages(resCaption.imageBase64, i, copyItems, secondRes.data, userAction)).catch(error => {
                        // Handle the error
                        console.log("merge image error", error)
                    });

                    promise.then((mergedImageDataUrl) => {
                        copyItems[i] = mergedImageDataUrl;
                    }


                    ).catch(() => console.log(`Promise ${i} rejected`)).finally((res) => {

                        console.log("Finally here")
                    });

                    processImagePromises.push(promise);

                }

                await Promise.all(processImagePromises);

                if (userAction === "edit") {
                    setCopyImages(copyItems);
                } else {
                    setCopyImages([...copyImages, ...copyItems]);
                }
                setLoader(false);
                setShowListingPage(true);

                setHeadlineArray(headlineArraysData);
                setDescriptionArray(descriptionArray);
                setCtaArray(ctaArrayData);
                setBulletpointsArray(bulletpointsArrayData);
                setReviewMessageArray(reviewMessageArraysData);
                setQuestionArray(questionArrayArray);
                setReviewNameArray(reviewNameArrayData);
                setDarkColorArray(lightColorArrayArray);
                setLightColorArray(darkColorArrayData);
            } else {

            }
        } catch (error) {
            await addErrorLogtoDebugWeb(error);
            handleErrors(error);
        } finally {
            setSelectedFile(null);
            setIsGenerateMoreClicked(false);
        }
    };

    const updateVariations = async (userRequestedIndex, variationNumber) => {
        // Extracting the bullet points using regular expressions
        // Split the response into lines
        try {
            // Split the input data by lines
            if (imagesBase64Array) {
                let i = userRequestedIndex;
                // for testing open below code
                const imagesBase64 = imagesBase64Array;
                const styleMaps = await getStyleMaps(
                    variationNumber,
                    lightColorArray[i],
                    darkColorArray[i]
                );
                const headline = headlineArray[i];
                const description = descriptionArray[i];
                const cta = ctaArray[i];
                const bulletpoints = bulletpointsArray[i];
                const reviewMessage = reviewMessageArray[i];
                const question = questionArray[i];
                const reviewName = reviewNameArray[i];

                const objGenerateSecondImage = {
                    title: headline,
                    description: description,
                    cta,
                    height: 1024,
                    width: 1024,
                    bulletpoints,
                    style: styleMaps,
                    question,
                    reviewMessage,
                    reviewName,
                };

                if (base64RequiredImage.includes(variationNumber)) {
                    objGenerateSecondImage.base64Image = imagesBase64; // All product do not required to send base 64 images
                }

                const secondRes = await API_FIREBASE.post(
                    "requestCaptionImageAdOverlayV1",
                    objGenerateSecondImage,
                    {
                        headers: {
                            authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY,
                        },
                    }
                );

                // await mergeBase64ImagesimagesBase64, i, copyItems, secondRes.data);
                // Call mergeImages with a wait
                return await mergeImages(
                    imagesBase64,
                    i,
                    copyItems,
                    secondRes.data,
                    "",
                    true // it is variations
                );
            }
        } catch (error) {
            handleErrors(error);
        } finally {
            setLoader(false);
        }
    };


    const addErrorLogtoDebugWeb = async (e) => {
        try {
            const userInfo = {
                errorLogs: "",
                userId: userService.getUserId(),
                generatedAdsNumber: 0,
            };
            await db
                .collection("addErrorLogtoDebugWeb")
                .doc(userService.getUserId())
                .set(userInfo, { merge: true });
        } catch (e) {
            console.log(e);
        }
    };



    const handleSubmit = (event) => {
        setErrorMessage("");

        userService.removeKeyLocalstorage("storeImages");
        setFilteredImages("");
        event.preventDefault();

        if (!formData.url) {
            setErrorMessage("Please enter valid store URL.");
            setTimeout(function () {
                setErrorMessage("");
            }, 3000);
        } else {
            setErrorMessage("");
            setStoreRequestLoader(true);
            setLoader(true);
            event.preventDefault();
            const apiUrl = constants.APPLICATION_INFO.STORE_FEATURE_API_URL;
            axios
                .get(apiUrl, {
                    params: {
                        api_key: process.env.REACT_APP_STORE_FEATURE_SCRAPEBEE_API_KEY,
                        url: constants.APPLICATION_INFO.HTTPS + formData.url,
                        render_js: true,
                        extract_rules: {
                            images: {
                                selector: "img",
                                type: "list",
                                output: { src: "img@src" },
                            },
                        },
                    },
                    timeout: 50000,
                })
                .then(function (htmlResponse) {
                    // handle success
                    const htmlContent = htmlResponse.data;
                    const imageUrls = [];
                    if (htmlResponse.data.images.length > 0) {
                        setImageTags(htmlResponse.data.images);
                    } else {
                        setErrorMessage(
                            "There is no image found. Please try with another URL."
                        );
                    }
                    userService.setKeyValueLocalstorage("imageUrls", imageUrls);
                    setLoader(false);
                    setStoreRequestLoader(false);
                })
                .catch((error) => {
                    if (error.code === "ECONNABORTED") {
                        // Handle timeout error
                        setURLErrorMessage(
                            "Cannot fetch products. Retry, or upload a product photo."
                        );
                    } else {
                        setURLErrorMessage(
                            "Please check your Store URL and try again later!"
                        );
                    }

                    setTimeout(function () {
                        setURLErrorMessage("");
                    }, 4000);
                    setLoader(false);
                    setStoreRequestLoader(false);
                });
        }
    };

    const selectImage = (items) => {
        setActive(items);
        setPopupImage(items.url);
        setImageTitle(items.title);
        userService.setKeyValueLocalstorage("storeSelectedImages", items.url);
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        setHighlight(true);
    };

    const handleDragLeave = () => {
        setHighlight(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = async (e) => {
        e.preventDefault();
        setHighlight(false);
        const file = e.dataTransfer.files[0];
        setSelectedFile(file);
        const acceptedTypes = [
            "image/jpeg",
            "image/jpg",
            "image/png",
            "image/webp",
        ];
        if (acceptedTypes.includes(file?.type)) {
            setSelectedFile(file);
            userService.setKeyValueLocalstorage("selectedFile", file);
            setErrorMessage("");
            setPopupImage(file);
            setModalOpen(true);
        } else {
            setSelectedFile(null);
            setShowModel(true);
            setErrorMessage("Only JPEG, JPG, PNG, and WEBP images are allowed!");
        }
    };

    useEffect(() => {
        if (isGenerateMoreClicked) {
            setLoader(true);
            setShowListingPage(false);
            setStoreRequestLoader(false);
        }
    }, [isGenerateMoreClicked]);
    const handleUseTheme = () => {
        navigate("/choose-theme");
    };

    return (
        <React.Fragment>
            {showListingPage && (
                <PreviewAdsListing
                    handleUpload={handleUpload}
                    setShowListingPage={setShowListingPage}
                    showListingPage={showListingPage}
                    copyImages={copyImages}
                    setResetPreviousPage={setResetPreviousPage}
                    selectedFile={selectedFileGenerateMore}
                    setIsGenerateMoreClicked={setIsGenerateMoreClicked}
                    listingContent={listingContent}
                    pageName="store"
                    updateVariations={updateVariations}
                    isVariationCompleted={isVariationCompleted}
                    setIsVariationCompleted={setIsVariationCompleted}
                ></PreviewAdsListing>
            )}

            {loading && !showListingPage && !storeRequestLoader && (
                <LoadingScreen></LoadingScreen>
            )}
            {loading && !showListingPage && storeRequestLoader && (
                <LoadingStore></LoadingStore>
            )}

            {!loading && !showListingPage && (
                <div className="container-fluid  ">
                    <div className="row">
                        <div className="">
                            <div className="top-contentDIv  ">
                                <div className="top-ranckking mt-10  ">
                                    <div className="divider-border-2 mb-50"></div>
                                    <div className="col-sm-12 store-enter-url-box ">
                                        <div className="col-sm-6 bg-primary">
                                            <form onSubmit={handleSubmit}>
                                                <div className="form-row form-container">
                                                    <div className="row  text-center-media">
                                                        <div className="col">
                                                            <span className="h2 font-weight-bold pad-15">
                                                                Enter your Store link:{" "}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* <div className="row p-2 text-center-media">
                        <div className="col">
                          <span className="h5 font-weight-bold pad-15">
                            (For best results, input a product listing page)
                          </span>
                        </div>
                      </div> */}
                                                    <div className="form-group input-container col-5 ">
                                                        <div className="https-text-in-input-box">
                                                            https://
                                                        </div>
                                                        <input
                                                            name="url"
                                                            placeholder="shop.com"
                                                            type="text"
                                                            value={formData.url}
                                                            className="custom-input https-padding modal-gradient-"
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    {urlErrorMessage && (
                                                        <div className="alert alert-danger" role="alert">
                                                            {urlErrorMessage}
                                                        </div>
                                                    )}
                                                    {!loading && (
                                                        <>
                                                            <div className="border-wrapper-new-landing-page-button/ btn-radius/">
                                                                {/* <button
                              type="submit"
                              className="next-wrapper1-new-landing-page store-button"
                            >
                              <b className="button-text-new-landing-page">
                                Enter
                              </b>
                            </button> */}
                                                                <StyledButton1
                                                                    name={"Enter "}
                                                                    type="submit"
                                                                    disabled={loading}
                                                                />
                                                            </div>
                                                            <div className="error-message">
                                                                {" "}
                                                                {errorMessage}
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    {filteredImages.length > 0 && (
                                        <div className="col-sm-12 ">
                                            {filteredImages.length > 0 && (
                                                <div
                                                    className="what-do-you-want-an-ad-for-wrapper landingadflonew"
                                                    title="What type of product do you sell?"
                                                >
                                                    <b className="what-do-you press-product">
                                                        Press a product:
                                                    </b>
                                                </div>
                                            )}

                                            <div className="row mb-20 mt-20 store-image--items__container">
                                                {filteredImages &&
                                                    filteredImages.length > 0 &&
                                                    filteredImages
                                                        .slice(0, currentBatch)
                                                        .map((items, index) => (
                                                            <div
                                                                className={`col-sm-2 col-xs-12 cursor-pointer store-image--item`}
                                                                key={`ad-listing-${index}`}
                                                                onClick={() => {
                                                                    selectImage(items);
                                                                    setModalOpen(true);
                                                                }}
                                                                data-toggle="modal"
                                                                data-target="#imageDetailModal"
                                                            >
                                                                <div className={`thumbnailListBG mar-10`}>
                                                                    <img
                                                                        className="img-responsive user-imgDiv pointerEvents  store-image"
                                                                        width={400}
                                                                        src={items.url}
                                                                        alt={userService.getValueFromLocalstorage(
                                                                            "uploadInput"
                                                                        )}
                                                                    />

                                                                    {/*<p>{items.title}</p>*/}

                                                                    <div className="clearfix"></div>
                                                                </div>
                                                            </div>
                                                        ))}
                                            </div>
                                            <div className="store-load-more-btn-container ">
                                                <div className="store-load-more-btn row mb-20 mt-20">
                                                    {currentBatch < filteredImages.length && (
                                                        <span
                                                            className="cursor-pointer  next-wrapper1 carousal-btn-generate-more next-btn-enable white-text-btn mar-top-20"
                                                            onClick={() => handleShowMore()}
                                                        >
                                                            Load more
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className="col-sm-12 col-xs-12 new-select-drag-or-drop-img-btn " >
                                        <div className=" col-sm-8 col-xs-11 col-md-6 col-lg-4  ">
                                            <form onSubmit={handleSubmit}>
                                                <div className="form-row   ">
                                                    <>
                                                        <div className="text-center h1 or-btn-store ">
                                                            OR
                                                        </div>
                                                        <div className="error-message text-center"> {errorMessage}</div>
                                                        <div className="img-upload-container  ">
                                                            <div
                                                                className="img-upload-2 upload-content "
                                                                onDragEnter={handleDragEnter}
                                                                onDragLeave={handleDragLeave}
                                                                onDragOver={handleDragOver}
                                                                onDrop={handleDrop}
                                                            >
                                                                <div>
                                                                    {!loading && (
                                                                        <div className="text-center inp-upload cursor-pointer">
                                                                            <input
                                                                                type="file"

                                                                                accept="image/*"
                                                                                onChange={handleFileChange}
                                                                                id="file"
                                                                            />
                                                                        </div>
                                                                    )}

                                                                    {selectedFile && (
                                                                        <div>
                                                                            <p>Selected File: {selectedFile.name}</p>
                                                                            <img
                                                                                src={URL.createObjectURL(selectedFile)}
                                                                                alt="Selected"
                                                                                onClick={() => {
                                                                                    setPopupImage(selectedFile);
                                                                                    setModalOpen(true);
                                                                                }}
                                                                                data-toggle="modal"
                                                                                data-target="#imageDetailModal"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {!loading && (
                                                                        <p className="text-center  ">
                                                                            <div>
                                                                                <img
                                                                                    src={upload_1}
                                                                                    alt="upload-btn"
                                                                                    width={22}
                                                                                    style={{ height: "unset" }}
                                                                                />
                                                                                Upload Product Photo

                                                                            </div>

                                                                            {/* <label className="maxupload" >
                                        Max Upload Size 400kb
                                  </label>*/}
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* image popup */}
            <div
                className={`modal modal modalWithmenu overflows-auto ${chooseBgPopup ? "show" : ""}`}
                tabIndex="-1"
                role="dialog"
                style={{
                    display: chooseBgPopup ? "block" : "none",
                    // background: "#0000006e",
                }}
            >
                <div
                    className="modal-dialog  modal-450 modal-350-mobile modal-dialog-centered text-open-sans top-20-percent animate-top-to-bottom"
                    role="document"
                >
                    <div className="modal-content modal-gradient-new font-family-vietnam-pro quick-ad-flow-modal">
                        <div className="modal-body text-center  form-group">
                            <div className="text-left font-20-regular text-weight-600">
                                Do you want to choose a background style or use AI?
                            </div>
                            <StyledButton1
                                onClick={handleUseTheme}
                                name={"Choose Theme"}
                                btnTheme={"light"}
                                btnHeight={"45px"}
                            />
                            <div className="text-center">
                                {" "}
                                <button
                                    className="btn btn-secondary specimore cursor-pointer"
                                    onClick={handleUpload}
                                    style={{ width: "100%" }}
                                >
                                    USE AI
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`modal mt-70 modalWithmenu overflows-auto ${isModalOpen ? "show" : ""}`}
                tabIndex="-1"
                role="dialog"
                style={{
                    display: isModalOpen ? "block" : "none",
                    // background: "#0000006e",

                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                    }}
                    className="  text-center"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                onClick={() => {
                                    setModalOpen(false);
                                }}
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">
                                    <img src={cross} alt="" />
                                </span>
                            </button>
                            {/* <h1 className="top-ranckking-h1 text-left">
                Image from your Store!
              </h1> */}
                        </div>
                        <div className="modal-body text-center user-imgDiv">
                            {/* <div className="col-md-11 text-center"> */}
                            <div className="pb-20 w-80-percent">
                                <div className="form-row form-container">
                                    <div className="row p-2 text-center-media">
                                        <div className="col">
                                            <span className="h2 font-weight-bold pad-15">
                                                What is your product?
                                            </span>
                                        </div>
                                    </div>
                                    <div className="form-group input-container col-5 ">
                                        <input
                                            name="productPrompt"
                                            placeholder="Ex: brown leather jacket"
                                            type="text"
                                            value={formData?.productPrompt}
                                            className="custom-input modal-gradient-"
                                            onChange={handlePromptChange}
                                        />
                                    </div>
                                    {productPromptErrorMessage && (
                                        <div className="alert alert-danger" role="alert">
                                            {productPromptErrorMessage}
                                        </div>
                                    )}
                                    {!loading && (
                                        <>
                                            {errorMessage && <div>{errorMessage}</div>}
                                            <div className="border-wrapper-new-landing-page-button/ btn-radius/">
                                                <div
                                                    className="styled-button-1-container"
                                                    onClick={() => {
                                                        setChooseBgPopup(true);
                                                        setModalOpen(false);
                                                    }}
                                                >
                                                    <div className="free-trail-next-wrapper next-wrapper-btn btn-width wht-bdr3">
                                                        <button
                                                            style={{ zIndex: 10 }}
                                                            className="continue-btn-free-trail cursor-pointer  font-18-regular"
                                                            data-dismiss="modal"
                                                        >
                                                            <span>Generate Ads</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            {/* </div> */}
                            {!selectedFile && (
                                <img
                                    width={408}
                                    height={408}
                                    src={popupImage}
                                    className="pointerEvents"
                                    alt={imageTitle}
                                />
                            )}
                            {selectedFile && (
                                <img
                                    width={408}
                                    height={408}
                                    src={URL.createObjectURL(selectedFile)}
                                    className="pointerEvents w-sm-100"
                                    alt={imageTitle}
                                    style={{ objectFit: "contain" }}
                                />
                            )}
                        </div>

                        {/* <div className="form-group text-center" title="Generate Image Ads">
              <button type="btn"
                className={`btn btn-primary `}>
                Generate Ad   </button>
            </div> */}
                    </div>
                </div>
            </div>

            {/* image popup*/}

            <div
                className={`modal ${isPaymentModalOpen ? "show" : ""}`}
                style={{
                    display: isPaymentModalOpen ? "block" : "none",
                    background: "#0000006e",
                }}
            >
                <div
                    className="modal-dialog top40 modal-dialog-centered text-open-sans"
                    role="document"
                >
                    <div className="modal-content modal-gradient-new">
                        <div className="modal-header">
                            <button
                                type="button"
                                onClick={() => {
                                    setPaymentModalOpen();
                                }}
                                className="close"
                            >
                                <span aria-hidden="true">x</span>
                            </button>
                        </div>
                        <div className="modal-body text-center">
                            <div className="mt-10">
                                <div className="text-white font-20-regular text-weight-600">
                                    Subscribe to generate unlimited ads
                                </div>
                                <CheckoutYearlyUltra>Subscribe</CheckoutYearlyUltra>
                                <CheckoutMonthlyUltra>Subscribe Monthly</CheckoutMonthlyUltra>
                            </div>
                        </div>
                        <div className="modal-footer text-center"></div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default Store;
